import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error-no-data',
  templateUrl: './error-no-data.component.html',
  styleUrls: ['./error-no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorNoDataComponent implements OnInit {

  @Input() msg: string;

  constructor() {
  }

  ngOnInit() {
  }

}
