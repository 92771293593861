<div class="boat-home__content">
  <div class="boat-home__wrap-icon">
    <app-seazone-icon
      *ngIf="icon; else imageTpl"
      [name]="icon"
      [color]="iconColor"
      [width]="iconWidth"
      [height]="iconHeight"
      class="boat-home__icon">
    </app-seazone-icon>
    <ng-template #imageTpl>
      <img *ngIf="image" class="boat-home__icon" [src]="image" alt="No boats" />
    </ng-template>
  </div>
  <div [class.purple]="isPurple" class="boat-home__content-text" [innerHTML]="title"></div>
</div>
