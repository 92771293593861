import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ErrorNoDataComponent} from './error-no-data/error-no-data.component';
import {NoDataComponent} from './no-data/no-data.component';

import {SeazoneIconsModule} from '../../modules/seazone-icons';

@NgModule({
  declarations: [NoDataComponent, ErrorNoDataComponent],
  imports: [
    CommonModule,
    SeazoneIconsModule,
  ],
  exports: [
    NoDataComponent,
    ErrorNoDataComponent,
  ],
})
export class NoDataModule { }
