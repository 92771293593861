import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {StubImages} from '@directives/image-stub';

import {SeazoneIcon, SeazoneIconColor} from '../../../modules/seazone-icons';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {

  @Input() title = 'No data';
  @Input() image: StubImages = StubImages.BOAT;
  @Input() icon: SeazoneIcon;
  @Input() iconColor: SeazoneIconColor = 'grey';
  @Input() iconWidth = 70;
  @Input() iconHeight = 70;
  @Input() isPurple = false;

  constructor() {
  }

}
